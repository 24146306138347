.column {
  display: block;
  box-sizing: border-box;
  position: relative;
  padding: 20px 24px;
  margin: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

.columnlist {
  border: 1px solid #666;
  background-color: #e8f3fc;
  border-radius: 0.2em;
  padding: 3px;
  cursor: move;
}

.panel_head {
  text-transform: uppercase;
  font-weight: 600;
  font-family: inherit;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  letter-spacing: 0.5px;
  line-height: 16px;
  padding-bottom: 10px;
}

.test{
  
}
